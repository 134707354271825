import React from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroImg2 from "../components/HeroImg2";
import PortfolioContent from "../components/PortfolioContent";

const Portfolio = () => {
    return (
        <div>
            <Navbar/>
            <HeroImg2 heading = "Personal Portfolio Website"/>
            <PortfolioContent/>
            <Footer/>
        </div>

    )
}

export default Portfolio;