import React from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroImg2 from "../components/HeroImg2";
import AiProjectContent from "../components/AiProjectContent";

const AiProject = () => {
    return (
        <div>
            <Navbar/>
            <HeroImg2 heading = "AI Recipe Generator"/>
            <AiProjectContent/>
            <Footer/>
        </div>

    )
}

export default AiProject;