import pro1 from "../assets/website.png"
import pro2 from "../assets/recipe.png"
import pro3 from "../assets/fedex.png"
import pro4 from "../assets/missing.png"

const ProjectCardData = [
    {
        imgsrc: pro1,
        title: "Personal Portfolio Website",
        text: "My professional portfolio website to view general information about me and projects I have worked on.",
        view: "/portfolio",
        source: "https://github.com/alieftwu/Portfolio",
    },
    {
        imgsrc: pro2,
        title: "AI Recipe Generator",
        text: "Combining my love for cooking and coding into one project! *WIP*",
        view: "/AIRecipeGenerator",
        source: "https://github.com/alieftwu",
    },
    {
        imgsrc: pro3,
        title: "Alert Management Dashboard",
        text: "A group class project where we designed a concept front-end application for FedEx.",
        view: "https://github.com/brisky-c/HCI_Fedex_Project",
        source: "https://github.com/brisky-c/HCI_Fedex_Project",
    },
    {
        imgsrc: pro4,
        title: "Top-Down RPG Detective Game",
        text: "A group class project where we are designing a Stardew Valley-type detective game. *WIP*",
        view: "https://github.com/alieftwu/Missing-Game",
        source: "https://github.com/alieftwu/Missing-Game",
    },
];

export default ProjectCardData;