import "./AboutContentStyles.css"

import React from 'react'
import {Link} from "react-router-dom";
import React1 from "../assets/recipe.png";
import React2 from "../assets/cookbook.png";


const AiProjectContent = () => {
  return <div className="about">
    <div className= "left">

        <h1>AI Recipe Generator</h1>
        <p> I am currently working on a full stack AI Recipe Generator that utilizes the OpenAI API to create unique and delicious food recipes that anyone can use!
            This project is providing me with experience in working with Python, using the OpenAI API, a bit of socket coding for back-end, and front-end design! I am hoping
            to finish this project by the end of Spring Semester 2024! The pictures to the right is what I hope the final product will look like!
        </p>
        <Link to = "https://github.com/alieftwu">
            <button className ="btn">Source</button>
        </Link>
    </div>
    <div className= "right">
        <div className ="img-container">
            <div className= "img-stack top">
                <img src = {React1} className="img" alt="true"/>
            </div>
            <div className= "img-stack bottom">
                <img src = {React2} className="img" alt="true"/>
                </div>
        </div>
    </div>
    </div>;
}

export default AiProjectContent;