import "./AboutContentStyles.css"

import React from 'react'
import {Link} from "react-router-dom";
import React1 from "../assets/website.png";
import React2 from "../assets/project1.jpg";


const PortfolioContent = () => {
  return <div className="about">
    <div className= "left">

        <h1>Personal Portfolio Website</h1>
        <p>Wanting to learn more about Web Development, during the Summer of 2023, I undertook the development of a personal portfolio website...the exact website you're on now!
            Through this project, I was able to learn more about HTML, Javascript, and CSS! It is fun to be able to update this page every so often with new projects
            I am working on! This is technically still a work in progress as I am still adding new project pages when you click on the view button
            on the project cards so you can read more about each individual projects. Check out the Github repo for this project!
        </p>
        <Link to = "https://github.com/alieftwu/Portfolio">
            <button className ="btn">Source</button>
        </Link>
    </div>
    <div className= "right">
        <div className ="img-container">
            <div className= "img-stack top">
                <img src = {React1} className="img" alt="true"/>
            </div>
            <div className= "img-stack bottom">
                <img src = {React2} className="img" alt="true"/>
                </div>
        </div>
    </div>
    </div>;
}

export default PortfolioContent;