
import React from 'react';
import "./index.css";
import Home from "./routes/Home";
import About from "./routes/About";
import Project from "./routes/Project";
import Contact from "./routes/Contact";
import Portfolio from "./routes/Portfolio";
import AiProject from "./routes/AiProject";


import {Route, Routes} from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/project" element={<Project />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path ="/Portfolio" element={<Portfolio />} />
      <Route path ="/AIRecipeGenerator" element={<AiProject />} />
    </Routes>
  );
}

export default App;
